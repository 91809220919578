#i-beam {
    width: 100%;
}

#i-beam-center {
  height: 50px;
  background: #B80000;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  width: 95%;
  margin: auto;
  padding: 5px 20px;
  display: flex;
  justify-content: space-between;
}

#i-beam-center > div {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#i-beam-top {
  height: 15px;
    background: #820300;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

#rivet {
  border-radius:100%;
  width: 15px; height: 15px;
  background: #7E1717;
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
}

#i-beam-center > p {
  color: white;
}

body {
    background-color: #269;
    background-image:
      linear-gradient(rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(90deg, rgba(255,255,255,.5) 2px, transparent 2px),
      linear-gradient(rgba(255,255,255,.28) 1px, transparent 1px),
      linear-gradient(90deg, rgba(255,255,255,.28) 1px, transparent 1px);
    background-size: 100px 100px, 100px 100px, 20px 20px, 20px 20px;
    background-position: -2px -2px, -2px -2px, -1px -1px, -1px -1px;
}